.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left;
}

.site-layout-content {
  background: #fff;
  padding-top: 12px;
  padding-bottom: 12px;
  min-height: 380px;
  text-align: center;
  max-width: 800px;
  margin: auto;
}

.logo {
  width: 30px;
  max-width: fit-content;
  
}
.subTitle {
  margin-top: -15px;
  font-size: 12px;
}
.content {
  display: none;
}